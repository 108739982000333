import { TypoXXS, TypoXXSBold } from '@atoms/Typos';
import styles from './css';

type Props = {
  inputValue?: string;
  maxLength: number;
};

const InputCounter = ({ inputValue = '', maxLength }: Props) => (
  <div className="input-counter-cont">
    {inputValue.length >= maxLength ? (
      <TypoXXSBold
        color="black700"
        text={`${inputValue?.length} / ${maxLength}`}
      />
    ) : (
      <TypoXXS color="black700" text={`${inputValue?.length} / ${maxLength}`} />
    )}
    <style jsx={true}>{styles}</style>
  </div>
);

export default InputCounter;
