import { css } from 'styled-jsx/css';

const styles = css`
  .input-counter-cont {
    margin-top: 4px;
    text-align: right;
  }
`;

export default styles;
